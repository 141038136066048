.with-errors {
  color: #eb596e;
  font-size: 11px;
  margin-top: 0rem;
  padding: 0;
  margin-left: .6rem;
  margin-top: -10px;
  margin-bottom: 0 !important;
}
.close-d{
  font-size: 1rem;
  color: white !important;
  background-color: #ed9323;
  z-index: 1;
  width: 100px;
  padding: 10px;
  display: inline-block;
  border: 2px solid #ed9323;
  text-transform: uppercase;
  border-radius: .5rem;
  -webkit-border-radius: .5rem;
  -moz-border-radius: .5rem;
  -ms-border-radius: .5rem;
  -o-border-radius: .5rem;
}
.close-d:hover{
  color: #fff !important;
}
.btn-commin:hover{
  color: #fff;
}
