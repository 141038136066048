.bg-light{
  background-color: #8a80cc59 !important;
}
.bg-done{
  background-color: #34b347;
  color: #fff;
  width:30px;
  height: 30px;
  padding: 5px;
  border-radius: 50% !important;
  -webkit-border-radius: 50% !important;
  -moz-border-radius: 50% !important;
  -ms-border-radius: 50% !important;
  -o-border-radius: 50% !important;
}
.bg-wrong span,
.bg-done span{
  display: inline-block;
  font-weight: normal;
  width: 100px !important;
  font-size: .8rem;
}
.bg-wrong{
  background-color: #ff0000;
  color: #fff;
  width:30px;
  height: 30px;
  padding: 5px;
  border-radius: 50% !important;
  -webkit-border-radius: 50% !important;
  -moz-border-radius: 50% !important;
  -ms-border-radius: 50% !important;
  -o-border-radius: 50% !important;
}
.table th, .table td {
  vertical-align: middle !important;
}
.history table thead tr{
  background-color: #f47e34;
  font-size: .8rem;
  border-top-right-radius: .5rem;
  border-top-left-radius: .5rem;
  font-style: normal;
  color: #fff;
  height: 70px;
}
.history table thead tr th{
  text-transform: uppercase;
  letter-spacing: 1.3px;
}
.history table thead tr,
.history table tbody tr{
  display: grid;
  align-content: center;
  grid-template-columns: repeat(4,1fr);
  /* border-bottom: 1px solid #ddd; */
}
.history table tbody tr:nth-of-type(odd){
  background-color: #eee;
}
.history table tbody tr:nth-of-type(even){
  background-color: #ddd;
}
.history table tbody tr p{
  text-align: center;
  margin-bottom: 0px !important;
}
.history .table th, .table td{
  place-self: center;
  justify-self: center;
  border: none !important;
}
small{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.table th, .table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
  padding-left: 5px;
  padding-right: 5px;
  text-align: center;
}
.color-success{
  color: #34b347;
}
.color-error{
  color: #ff0000
}