@import url("https://fonts.googleapis.com/css?family=Arvo:700|Open+Sans");
html {
  overflow-x: hidden;
}

body {
  background: #fff;
  font-size: 17px;
  font-weight: bold;
  font-family: "Cairo", sans-serif;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-font-smoothing: subpixel-antialiased;
  color: #abacae;
  line-height: 25px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4 {
  font-size: 38px;
  color: #222222;
  text-align: start;
  font-weight: 700;
  font-family: "Cairo", sans-serif;
}

a {
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

a:hover {
  text-decoration: none;
}

a a:focus {
  outline: none;
}

p {
  font-weight: 400;
  font-family: "Cairo", sans-serif;
  margin: 0px;
  text-align: start;
  font-size: 14px;
}

ul,
ol {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}

ul li,
ol li {
  list-style: none;
}

a:not([href]):not([tabindex]) {
  color: #fff;
}

.section-header {
  margin-bottom: 30px;
}

.section-header p {
  text: center;
  font-weight: 400;
  line-height: 26px;
}

.section-title {
  font-size: 30px;
  color: #0d025b;
  display: inline-block;
  font-weight: 700;
  position: relative;
}

.shape {
  height: 1px;
  margin: 0 auto 30px;
  position: relative;
  width: 60px;
  background-color: #ed9323;
}

.padding-none {
  padding: 0px;
}

.bg-gray {
  background: #f9f9f9;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background: rgba(61, 96, 244, 0.3);
}

.btn {
  font-size: 14px;
  padding: 10px 30px;
  cursor: pointer;
  font-weight: 400;
  color: #fff;
  border-radius: 10px;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
  display: inline-block;
}

.btn:focus,
.btn:active {
  box-shadow: none;
  outline: none;
  color: #fff;
}



.btn-border {
  color: #ed9323;
  background-color: transparent;
  border: 1px solid #ed9323;
  border-radius: 10px;
  text-transform: uppercase;
}

.btn-border:hover {
  color: #fff;
  border-color: #0d025b;
  background-color: #0d025b;
}

.btn-border:focus {
  color: #fff;
  border-color: #ed9323;
  background-color: #ed9323;
}

.btn-lg {
  padding: 14px 33px;
  text-transform: uppercase;
  font-size: 16px;
}

.btn-rm {
  padding: 7px 0px;
  color: #ed9323;
  text-transform: capitalize;
}

.btn-rm:hover {
  color: #ed9323;
}

button:focus {
  outline: none !important;
}

.icon-close,
.icon-check {
  color: #ed9323;
}

.social-icon a {
  color: #666;
  background: #fff;
  width: 32px;
  height: 32px;
  line-height: 34px;
  display: inline-block;
  text-align: center;
  border-radius: 100%;
  font-size: 16px;
  margin: 15px 6px 12px 4px;
  transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
}

.social-icon a:hover {
  color: #fff !important;
}

.social-icon .facebook:hover {
  background: #3b5999;
}

.social-icon .twitter:hover {
  background: #4a9cec;
}

.social-icon .instagram:hover {
  background: #d6274d;
}

.social-icon .linkedin:hover {
  background: #1260a2;
}

.social-icon .google:hover {
  background: #ce332a;
}

/* ScrollToTop */
a.back-to-top {
  display: none;
  position: fixed;
  bottom: 18px;
  right: 15px;
  text-decoration: none;
}

a.back-to-top i {
  display: block;
  font-size: 22px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  color: #fff;
  background: #ed9323;
  border-radius: 10px;
  text-align: center;
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 0 4px 8px rgba(0, 0, 0, 0.28);
}

a.back-to-top:hover,
a.back-to-top:focus {
  text-decoration: none;
}
.footer-logo {
  color: #ed9323;
}
/* Preloader */
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 9999999;
}

.loader {
  top: 50%;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  position: relative;
  margin: 0 auto;
}

#loader-1:before,
#loader-1:after {
  content: "";
  position: absolute;
  top: -10px;
  left: -10px;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  border: 7px solid transparent;
  border-top-color: #ed9323;
}

#loader-1:before {
  z-index: 100;
  animation: spin 1s infinite;
}

#loader-1:after {
  border: 7px solid #f2f2f2;
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.section-padding {
  padding: 140px 0;
}

hr {
  border-top: 1px dotted #ddd;
}

/* ==========================================================================
  8. Features Section Style
   ========================================================================== */
#features {
  background: #fff;
}

#features .icon {
  display: inline-block;
  width: 60px;
  height: 60px;
  border-radius: 4px;
  text-align: center;
  position: relative;
  z-index: 1;
}

#features .content-left span {
  float: right;
}

#features .content-left .text {
  text-align: right;
}

#features .content-right span {
  float: left;
}

#features .box-item {
  box-shadow: 0 0 12px #f4f4f4;
  padding: 15px;
  line-height: 22px;
  margin-top: 30px;
  border-radius: 3px;
  background-color: #fff;
  -webkit-transition: all 0.3s ease-in-out;
  -mox-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  position: relative;
  top: 0;
}

#features .box-item .icon {
  text-align: center;
  margin: 12px;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

#features .box-item .icon i {
  color: #ed9323;
  font-size: 30px;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

#features .box-item .text h4 {
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

#features .box-item .text p {
  font-size: 14px;
  line-height: 26px;
}

#features .box-item:hover {
  box-shadow: 0 10px 22px 10px rgba(27, 38, 49, 0.1);
}

#features .box-item:hover h4 {
  color: #ed9323;
}

#features .show-box {
  margin-top: 50px;
}

#features .show-box img {
  width: 100%;
}

/* Services Item */
.services-item {
  padding: 20px;
  border-radius: 4px;
  text-align: center;
  margin: 15px 0;
  box-shadow: 0 0 12px #f4f4f4;
  transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
}

.services-item .icon {
  border: 1px solid #f1f1f1;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  margin: 0 auto;
  transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
}

.services-item .icon i {
  font-size: 30px;
  color: #ed9323;
  line-height: 70px;
  transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
}

.services-item .services-content h3 {
  margin-top: 10px;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.services-item .services-content h3 a {
  font-size: 16px;
  color: #585b60;
}

.services-item .services-content h3 a:hover {
  color: #ed9323;
}

.services-item:hover {
  box-shadow: 0 10px 22px 10px rgba(27, 38, 49, 0.1);
}

.services-item:hover .icon {
  background: #0d025b;
}

.services-item:hover .icon i {
  color: #fff;
}

#service {
  position: relative;
}

.about-area .about-wrapper {
  height: 100%;
  width: 100%;
  display: table;
}

.about-area .about-wrapper > div {
  vertical-align: middle;
  display: table-cell;
}

.about-area img {
  border-radius: 4px;
}

.about-area .content {
  margin-top: 15px;
}

.about-area .content p {
  margin-bottom: 30px;
}

#cta {
  padding: 60px 0;
}

.navbar-brand {
  position: relative;
  padding: 0px;
}
.navbar-brand h5 {
  color: #ed9323 !important;
}
.top-nav-collapse {
  background: #fff;
  z-index: 999999;
  padding: 30px !important;
  top: 0px !important;
  box-shadow: 0px 3px 6px 3px rgba(0, 0, 0, 0.06);
  background: #fff !important;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
}
.navbar-brand {
  margin-left: 1rem !important;
}
.top-nav-collapse .navbar-brand {
  top: 0px;
}

.top-nav-collapse .navbar-brand img {
  width: 70%;
}

.top-nav-collapse .navbar-nav .nav-link {
  color: #585b60 !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.top-nav-collapse .navbar-nav .nav-link:hover {
  cursor: pointer;
  color: #ed9323 !important;
  border-color: #ed9323 !important;
}

.top-nav-collapse .navbar-nav .nav-link:hover {
  color: #ed9323 !important;
}

.navbar-expand-md .navbar-toggler {
  background: transparent;
  border: 1px solid #ed9323;
  color: #ed9323;
  border-radius: 4px;
  cursor: pointer;
}



.indigo {
  background: transparent;
}

.menu-bg {
  background: transparent;
}


.navbar li.active a.nav-link {
  color: preset;
}

.dropdown-toggle::after {
  display: none;
}

.dropdown-menu {
  margin: 0;
  padding: 0;
  display: none;
  position: absolute;
  z-index: 99;
  min-width: 210px;
  background-color: #fff;
  white-space: nowrap;
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  animation: fadeIn 0.4s;
  -webkit-animation: fadeIn 0.4s;
  -moz-animation: fadeIn 0.4s;
  -o-animation: fadeIn 0.4s;
  -ms-animation: fadeIn 0.4s;
}

.dropdown-menu:before {
  content: "";
  display: inline-block;
  position: absolute;
  bottom: 100%;
  left: 20%;
  margin-left: -5px;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  border-bottom: 10px solid #fff;
}

.dropdown:hover .dropdown-menu {
  display: block;
  position: absolute;
  text-align: left;
  top: 100%;
  border: none;
  animation: fadeIn 0.4s;
  -webkit-animation: fadeIn 0.4s;
  -moz-animation: fadeIn 0.4s;
  -o-animation: fadeIn 0.4s;
  -ms-animation: fadeIn 0.4s;
}

.dropdown .dropdown-menu .dropdown-item {
  width: 100%;
  padding: 12px 20px;
  font-size: 14px;
  color: #333;
  border-bottom: 1px solid #f1f1f1;
  text-decoration: none;
  display: inline-block;
  float: left;
  clear: both;
  position: relative;
  outline: 0;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
}

.dropdown .dropdown-menu .dropdown-item:last-child {
  border-bottom: none;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.dropdown .dropdown-menu .dropdown-item:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.dropdown .dropdown-item:focus,
.dropdown .dropdown-item:hover,
.dropdown .dropdown-item.active {
  color: #ed9323;
  background: #f7f7f7;
}

.dropdown-item.active,
.dropdown-item:active {
  background: transparent;
}

.fadeInUpMenu {
  -webkit-animation-name: fadeInUpMenu;
  animation-name: fadeInUpMenu;
}

/* ==========================================================================
3. Hero Area
========================================================================== */
#hero-area {
  /* background-image: url(../img/hero-area.svg); */
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 650px;
  position: relative;
  overflow: hidden;
  padding: 140px 0 80px;
}

#hero-area .contents .head-title {
  color: #222222;
  font-size: 42px;
  font-weight: 700;
  line-height: 60px;
  margin-bottom: 10px;
}

#hero-area .contents .header-button {
  margin-top: 20px;
  color: #222222;
}

#hero-area .contents .header-button .btn {
  margin-right: 10px;
}

.sloder-img {
  background: #34363a;
}

/* Team Item */
.team-item:hover {
  box-shadow: 0 10px 22px 10px rgba(27, 38, 49, 0.1);
}

.team-item {
  margin: 15px 0;
  border-radius: 0px;
  box-shadow: 0px 2px 18px 0px rgba(198, 198, 198, 0.3);
  transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  background: #fff;
}

.team-item .team-img {
  float: left;
  width: 200px;
  margin-right: 30px;
}

.team-item .contetn {
  padding: 15px 50px;
  height: 200px;
}

.team-item .social-icons {
  padding-top: 11px;
  transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
}

.team-item .social-icons li {
  display: inline-block;
  margin-right: 10px;
}

.team-item .social-icons li a {
  letter-spacing: 0px;
  outline: 0 !important;
}

.team-item .social-icons li a i {
  font-size: 18px;
  display: block;
  transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
}

.team-item .social-icons li .lni-facebook-filled {
  color: #3b5998;
}

.team-item .social-icons li .lni-twitter-filled {
  color: #00aced;
}

.team-item .social-icons li .lni-instagram-filled {
  color: #fb3958;
}

.team-item .info-text {
  margin-bottom: 10px;
}

.team-item .info-text h3 {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: 5px;
}

.team-item .info-text h3 a {
  color: #333;
}

.team-item .info-text h3 a:hover {
  color: #ed9323;
}

.team-item .info-text p {
  margin: 0;
  color: #888;
}

.team-item:hover .team-overlay {
  opacity: 1;
}

/* ==========================================================================
   Pricing Table Style
   ========================================================================== */
#pricing {
  text-align: center;
}

#pricing .title {
  padding-top: 20px;
}

#pricing .title h3 {
  text-transform: uppercase;
  color: #333;
  font-size: 18px;
}

#pricing .title .month-plan {
  font-size: 16px;
  font-weight: 500;
  color: #333;
}

#pricing .table {
  margin-top: 15px;
  padding: 30px;
  border-radius: 4px;
  border: none !important;
  box-shadow: 0px 2px 18px 0px rgba(198, 198, 198, 0.3);
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

#pricing .table .icon-box {
  position: relative;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
  background-color: #ebedef;
  margin-bottom: 20px;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

#pricing .table .icon-box i {
  color: #ed9323;
  line-height: 80px;
  font-size: 30px;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

#pricing .table .pricing-header {
  position: relative;
  text-align: center;
}

#pricing .table .pricing-header .price-value {
  font-size: 24px;
  color: #ed9323;
  position: relative;
  text-align: center;
  font-weight: 700;
}

#pricing .table .pricing-header .price-value sup {
  font-size: 16px;
  font-weight: 500;
  top: -18px;
}

#pricing .table .pricing-header .price-value span {
  font-size: 15px;
  color: #abacae;
  font-weight: 400;
}

#pricing .table .description {
  text-align: center;
  padding: 0px 50px;
  margin-bottom: 20px;
}

#pricing .table .description li {
  font-size: 14px;
  font-weight: 400;
  color: #abacae;
  padding: 4px 0;
}

#pricing .table .description li:last-child {
  border-bottom: none;
}

#pricing .table:hover {
  background: #ffffff;
  box-shadow: 0 10px 22px 10px rgba(27, 38, 49, 0.1);
}

#pricing .table:hover .icon-box {
  background: #ed9323;
}

#pricing .table:hover .icon-box i {
  color: #fff;
}

#pricing #active-tb {
  background: #ffffff;
  box-shadow: 0 10px 22px 10px rgba(27, 38, 49, 0.1);
}

#pricing #active-tb .icon-box {
  background: #ed9323;
}

#pricing #active-tb .icon-box i {
  color: #fff;
}

#pricing .active {
  z-index: 99999;
}

.testimonial {
  position: relative;
  background-color: #ed9323;
}

.testimonial-item {
  background: #fff;
  border-radius: 4px;
  text-align: center;
  padding: 30px 20px;
}

.testimonial-item .img-thumb {
  position: relative;
  margin: 15px 15px 15px 0;
}

.testimonial-item .img-thumb img {
  border-radius: 50%;
  display: inline-block;
  width: inherit;
  padding: 7px;
}

.testimonial-item .content {
  overflow: hidden;
}

.testimonial-item .content .description {
  width: 100%;
  color: #333;
}

.testimonial-item .content .star-icon i {
  color: #ed9323;
}

.testimonial-item .info h2 {
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 30px;
  margin: 0;
}

.testimonial-item .info h2 a {
  color: #333;
}

.testimonial-item .info h3 {
  margin: 0;
  clear: both;
  font-size: 14px;
  font-weight: 500;
  line-height: 26px;
  margin-bottom: 10px;
}

.testimonial-item .info h3 a {
  color: #333;
}

.testimonial-item .info .indicator {
  font-size: 26px;
  font-weight: 700;
  color: #ed9323;
}

.testimonial-item .icon-social {
  margin-top: 30px;
}

.testimonial-item .icon-social a {
  color: #666;
  background: #fff;
  width: 32px;
  height: 32px;
  line-height: 34px;
  display: inline-block;
  text-align: center;
  border-radius: 100%;
  font-size: 15px;
  margin: 15px 6px 12px 4px;
  transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
}

.testimonial-item .icon-social a:hover {
  color: #fff;
}

.testimonial-item .icon-social .facebook:hover {
  background: #3b5999;
}

.testimonial-item .icon-social .twitter:hover {
  background: #4a9cec;
}

.testimonial-item .icon-social .instagram:hover {
  background: #d6274d;
}

.testimonial-item .icon-social .linkedin:hover {
  background: #1260a2;
}

.testimonial-item .icon-social .google:hover {
  background: #ce332a;
}
#cta {
  background-image: linear-gradient(
    to right,
    #0d0250,
    #170b5a,
    #211565,
    #291f6f,
    #32287a
  );
}
.owl-pagination {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: -40px;
}

.owl-carousel .owl-dots {
  text-align: center;
  margin-top: 20px;
}

.owl-carousel button.owl-dot {
  display: inline-block;
  zoom: 1;
  display: inline;
  text-align: center;
}

.owl-carousel button.owl-dot span {
  display: block;
  width: 12px;
  height: 12px;
  margin: 2px 4px;
  filter: alpha(opacity=50);
  opacity: 1;
  border-radius: 30px;
  background: #fff;
  border: 2px solid #fff;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
  transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
}

.owl-carousel button.owl-dot.active span,
.owl-carousel button.owl-dot.clickable,
.owl-carousel button.owl-dot:hover span {
  background: #ed9323;
}

.slick-slider {
  padding: 80px 0;
}

.slider-center img {
  opacity: 0.7;
  transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  padding: 63px 63px;
  position: relative;
  text-align: center;
}

.slider-center .slick-center img {
  -moz-transform: scale(1.9);
  -ms-transform: scale(1.9);
  -o-transform: scale(1.9);
  -webkit-transform: scale(1.9);
  opacity: 1;
  transform: scale(1.9);
}

.form-control {
  width: 100%;
  margin-bottom: 10px;
  font-size: 14px;
  border-radius: 4px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  padding: 10px;
  border: 1px solid #f1f1f1;
  background-color: #f1efff;
}

.form-control:focus {
  border-color: #ed9323;
  box-shadow: none;
  outline: none;
}

textarea {
  border-radius: 4px !important;
}

.form-control:focus {
  box-shadow: none;
  outline: none;
}

.btn.disabled,
.btn:disabled {
  opacity: 1;
}

.contact-form-area h2 {
  font-size: 18px;
  text-transform: uppercase;
}

.contact-right-area {
  margin-left: 50px;
}

.contact-right-area .contact-title {
  margin-bottom: 20px;
}

.contact-right-area .contact-title h1 {
  font-size: 22px;
}

.contact-right {
  padding: 4px;
}

.contact-right .single-contact {
  margin: 30px 0px;
  padding: 3px 55px;
  position: relative;
  color: #abacae;
}

.contact-right .single-contact p {
  margin-bottom: 0px;
}

.contact-right .single-contact p a {
  color: #abacae;
}

.contact-right .contact-icon {
  background: #ed9323;
  color: #fff;
  border-radius: 4px;
  font-size: 20px;
  height: 40px;
  left: 0;
  padding-top: 8px;
  position: absolute;
  text-align: center;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 40px;
}

.h3 {
  float: right;
  font-size: 16px;
}

/* Footer Area Start */
/* .footer-area {
  position: relative;
  padding: 100px 0px 0px;
  background: #fff;
} */

/* .footer-area .footer-titel {
  font-size: 18px;
  color: #333333;
  font-weight: 500;
  padding-bottom: 20px;
  letter-spacing: 0.5px;
} */

/* .footer-area .footer-titel span {
  color: #ccc;
  font-weight: 400;
}

.footer-area .textwidget p {
  color: #333;
}

.footer-area .footer-link li {
  margin-bottom: 10px;
}

.footer-area .footer-link li a {
  color: #333;
  font-size: 14px;
  font-weight: 400;
  position: relative;
} */

/* .footer-area .footer-link li a:hover {
  color: #ed9323;
}

.footer-area .address li {
  margin-bottom: 20px;
}

.footer-area .address li a {
  color: #666666;
  font-size: 14px;
  line-height: 30px;
  font-weight: 400;
}

.footer-area .address li a i {
  font-size: 22px;
  width: 32px;
  vertical-align: middle;
  height: 36px;
  text-align: center;
  display: inline-block;
  margin-right: 5px;
  line-height: 32px;
  float: left;
}

.footer-area #subscribe-form {
  margin-top: 10px;
}

.footer-area #subscribe-form .form-group {
  position: relative;
} */

/* .footer-area #subscribe-form .form-group .btn-common {
  position: absolute;
  top: 0;
  right: 0;
  padding: 7px 15px;
  height: 52px;
  border-radius: 0;
  background: transparent;
  color: #ed9323;
} */



.social-icon a {
  color: #0d025b;
  background: #fff;
  width: 32px;
  height: 32px;
  line-height: 34px;
  display: inline-block;
  text-align: center;
  border-radius: 4px;
  border: 2px solid #ed9323;
  font-size: 16px;
  margin: 15px 6px 12px 4px;
  transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
}

.social-icon a:hover {
  color: #fff !important;
  border: none !important;
}

.social-icon .facebook:hover {
  background: #3b5999;
}

.social-icon .twitter:hover {
  background: #4a9cec;
}

.social-icon .instagram:hover {
  background: #d6274d;
}

.social-icon .linkedin:hover {
  background: #1260a2;
}

.social-icon .google:hover {
  background: #ce332a;
}
.text-warning {
  color: #ed9323 !important;
}
.btn-width-image {
  width: 140px !important;
  height: 40px !important;
}
.text-small {
  font-size: 15px !important;
}
.back-to-home {
  cursor: pointer;
}
#hero-area .lead {
  line-height: 40px !important;
}
.line-height {
  line-height: 40px !important;
}
.carousel-indicators .active {
  background-color: #abacae !important;
}
.carousel-indicators li {
  background-color: #ed9323 !important;
}
.logo {
  width: 70px !important;
}
.footer-logo {
  width: 200px !important;
}
a.back-to-top i:hover {
  background-color: #0d025b;
  border: 2px solid #ffffff !important;
}
.tab-img {
  width: 50px !important;
  height: 50px !important;
  border-radius: 20px !important;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border-color: #0d0250 !important;
  background-color: #0d0250;
}
@media (max-width: 767px) {
  .section-header.topop,
  .confirm {
    padding-top: 80px !important;
  }
  .tab-img {
    width: 70px !important;
  }
  .section-header.electric {
    margin-bottom: 30px;
    margin-top: 80px;
  }
  .section-header.telecom-services {
    padding-top: 100px !important;
  }

  .telecom .section-header h2 {
    margin-bottom: 0 !important;
  }
  .telecom .section-header {
    padding-top: 50px;
  }
  .telecom-tervices {
    padding-top: 70px !important;
  }
  .main-tabs {
    padding-top: 70px !important;
  }
  .main-div {
    min-height: 500px !important;
  }
}
.tab-pane .services-item,
.bill .services-item,
.electrtcity .services-item {
  text-align: left !important;
}
.bg-success {
  background-color: #ed9323 !important ;
}
.sub-text {
  color: #0d0250 !important;
}
.confirm .icon i {
  top: -26px;
  left: -3px;
}
/* .wrong .icon i {
  left: -5px !important;
} */
.form-tabs .services-item {
  text-align: left !important;
}
.w-100 {
  width: 100% !important;
}
.telecom .services-item .item-boxes {
  text-align: left !important;
  border: 1px solid #0d0250;
}
.image-radius {
  border-radius: 4px !important;
}

#register {
  color: #6a1b9a;
}
input:not([type="radio"]) {
  padding: 10px;
  width: 100%;
  font-size: 15px;
  font-family: Raleway;
  /* border: 1px solid #aaaaaa; */
  border-radius: 10px;
  -webkit-appearance: none;
}
.tab input:focus {
  border: 1px solid #6a1b9a !important;
  outline: none;
}

input.invalid {
  border: 1px solid #e03a0666;
}

.tab {
  display: none;
}

button:hover {
  opacity: 0.8;
}

button:focus {
  outline: none !important;
}
.all-steps {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
  width: 100%;
  display: none;
  justify-content: center;
}

.step {
  height: 40px;
  width: 40px;
  margin: 0 2px;
  background-color: #bbbbbb;
  border: none;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  color: #6a1b9a;
  opacity: 0.5;
}

.step.active {
  opacity: 1;
}

.step.finish {
  color: #fff;
  background: #6a1b9a;
  opacity: 1;
}

.all-steps {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
}

.thanks-message {
  display: none;
}
.thanks-message .p-icon.position-relative {
  top: -25px;
  left: -5px;
}
.telecom-services .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active .section-title {
  color: #f4f4f4 !important;
}
.main-div {
  min-height: 650px;
}
.form-group {
  margin-bottom: 0rem !important;
}
label {
  color: #0d0250;
  font-size: 12px;
}
.form-control::placeholder {
  color: #0d0250 !important;
  opacity: 0.5;
  font-family: "Cairo", sans-serif;
}
.bg-green {
  background-color: darkgreen;
}
.form-group .lead {
  font-size: 0.8rem;
}
.card-logos {
  width: 40px;
  height: 40px;
}
.main-div {
  min-height: 650px;
}
.form-group {
  margin-bottom: 0rem !important;
}
label {
  color: #0d0250;
  font-size: 12px;
}
.howBillPaymentInfo .modal-body {
  text-align: center !important;
}
.modal-footer {
  justify-content: center;
}
.form-control::placeholder {
  color: #0d0250 !important;
  opacity: 0.5;
}
.error {
  color: #eb596e;
  font-size: 11px;
  margin-top: 0rem;
  padding: 0;
  display: block;
  margin-top: -10px;
  margin-bottom: 0 !important;
}
a {
  color: #ed9323;
  text-decoration: none;
}
a:hover {
  color: #fff;
}
label {
  margin-bottom: 0;
}
p {
  margin-bottom: 1rem !important;
}
h6 {
  font-size: 0.8rem;
}

#register {
  color: #6a1b9a;
}
input:not([type="radio"]) {
  padding: 10px;
  width: 100%;
  font-size: 17px;
  font-family: Raleway;
  /* border: 1px solid #aaaaaa; */
  border-radius: 10px;
  -webkit-appearance: none;
}
.tab input:focus {
  border: 1px solid #6a1b9a !important;
  outline: none;
}

input.invalid {
  border: 1px solid #e03a0666;
}

.tab {
  display: none;
}

button:hover {
  opacity: 0.8;
}

button:focus {
  outline: none !important;
}
.all-steps {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
  width: 100%;
  display: none;
  justify-content: center;
}

.step {
  height: 40px;
  width: 40px;
  margin: 0 2px;
  background-color: #bbbbbb;
  border: none;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  color: #6a1b9a;
  opacity: 0.5;
}

.step.active {
  opacity: 1;
}

.step.finish {
  color: #fff;
  background: #6a1b9a;
  opacity: 1;
}

.all-steps {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
}

.thanks-message {
  display: none;
}
.thanks-message .p-icon.position-relative {
  top: -25px;
  left: -5px;
}
.telecom-services .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active .section-title {
  color: #f4f4f4 !important;
}
.main-div {
  min-height: 650px;
}
.form-group {
  margin-bottom: 0rem !important;
}
label {
  text-align: start;
  color: #0d0250;
  font-size: 12px;
}
.form-control::placeholder {
  color: #0d0250 !important;
  opacity: 0.5;
  font-family: "Cairo", sans-serif;
}
.bg-green {
  background-color: darkgreen;
}
.card-logos {
  width: 40px;
  height: 40px;
}
.services-item.form {
  text-align: start;
  padding: 0 !important;
}
.bg-warning {
  background-image: linear-gradient(
    to right,
    #ff9810,
    #ffa93f,
    #ffb961,
    #ffc882,
    #ffd7a3
  );
}
.f-28 {
  font-size: 28px !important;
}
.intl-tel-input {
  width: 100%;
}
.intl-tel-input .country-list {
  max-width: 270px;
  position: unset !important;
}
.tab-content p {
  display: flex;
}
.contact-block {
  box-shadow: 0px 10px 50px 0px rgba(84, 110, 122, 0.15);
  background-color: #ffffff !important;
}
.rounded {
  border-radius: 15px !important;
}
.rounded-left {
  border-top-left-radius: 15px !important;
  border-bottom-left-radius: 15px !important;
}
.bg-defult {
  background: #f47e34 !important;
}
.text-defult {
  color: #f47e34 !important;
}
.f-bold {
  font-weight: bold;
}
.text-end {
  text-align: end !important;
}
.text-white {
  font-size: 14px;
  color: #fff !important;
}
.text-end {
  text-align: end !important;
}
a.dropdown-toggle {
  color: #fff !important;
}
.main-body p {
  font-size: 16px !important;
  font-family: monospace !important;
  line-height: 1.7 !important;
  font-weight: normal !important;
}
.tab-content p {
  display: flex;
}
.text-sub-white {
  font-size: 13px;
  font-weight: normal;
  color: #ffffff87 !important;
}
.f-bold {
  font-weight: bold;
}
.dropdown-menu {
  border: none;
}
.dropdown-menu {
  position: absolute;
  top: 0px;
  left: 0px;
  will-change: transform;
  transform: translate3d(17px, 6px, 0px) !important;
  -webkit-transform: translate3d(17px, 6px, 0px) !important;
  -moz-transform: translate3d(17px, 6px, 0px) !important;
  -ms-transform: translate3d(17px, 6px, 0px) !important;
  -o-transform: translate3d(17px, 6px, 0px) !important;
}
.modal-confirm {
  color: #636363;
  width: 400px;
}
.modal-confirm .modal-content {
  padding: 20px;
  border-radius: 5px;
  border: none;
  text-align: center;
  font-size: 14px;
}
.modal-confirm .modal-header {
  border-bottom: none;
  position: relative;
}
.modal-confirm h4 {
  text-align: center;
  font-size: 26px;
  margin: 30px 0 -10px;
}
.modal-confirm .close {
  position: absolute;
  top: -5px;
  right: -2px;
}
.modal-confirm .modal-body {
  color: #999;
}
.modal-confirm .modal-footer {
  border: none;
  text-align: center;
  border-radius: 5px;
  font-size: 13px;
  padding: 10px 15px 25px;
}
.modal-confirm .modal-footer a {
  color: #999;
}
.modal-confirm .icon-box {
  width: 80px;
  height: 80px;
  margin: 0 auto;
  border-radius: 50%;
  z-index: 9;
  text-align: center;
  border: 3px solid #f15e5e;
}
.modal-confirm .icon-box.success {
  border-color: #60c7c1;
}
.modal-confirm .icon-box .material-icons {
  color: #f15e5e;
  font-size: 46px;
  display: inline-block;
  margin-top: 13px;
}
.modal-confirm .icon-box .material-icons.success {
  color: #60c7c1;
}
.color-green{
  background-color: #289672;
  color: #fff !important;
}
.modal-confirm .btn,
.modal-confirm .btn:active {
  color: #fff;
  border-radius: 4px;
  background: #60c7c1;
  text-decoration: none;
  transition: all 0.4s;
  line-height: normal;
  min-width: 120px;
  border: none;
  min-height: 40px;
  border-radius: 3px;
  margin: 0 5px;
}
.modal-confirm .btn-secondary {
  background: #c1c1c1;
}
.modal-confirm .btn-secondary.success {
  background-color: #60c7c1;
}
.modal-confirm .btn-secondary:hover,
.modal-confirm .btn-secondary:focus {
  background: #a8a8a8;
}
.modal-confirm .btn-secondary.success:hover,
.modal-confirm .btn-secondary.success:focus {
  background-color: #60c7c1;
}
.modal-confirm .btn-danger {
  background: #f15e5e;
}
.modal-confirm .btn-danger:hover,
.modal-confirm .btn-danger:focus {
  background: #ee3535;
}
.trigger-btn {
  display: inline-block;
  margin: 100px auto;
}

.contact-block {
 margin: 0 10px;
}
/* -----------------------------Genarel Commen------------------------------- */
/* 1.0- Button Primary */
.btn-common {
  background-color: #ed9323;
  position: relative;
  z-index: 1;
  border: 2px solid #ed9323;
  text-transform: uppercase;
}
.btn-common:hover {
  color: #0d025b;
  background: transparent;
  font-weight: bold;
  border: 2px solid #0d025b;
  box-shadow: 0 6px 22px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
}
@media only screen and (min-width: 500px){
.paypal-button-container {
  overflow: hidden;
  width: unset;
  max-width: unset;
  display: grid !important;
  grid-template-columns: repeat(3,1fr) !important;
  gap: 10px !important;
}
}

.card-fields-container{
  display:none
}
.paypal-button.paypal-button-color-black{
  background-color: #00aced !important;
}
.fees{
  color: #ed9323;
  font-size: 11px;
  margin-top: 0rem;
  padding: 0;
  margin-top: -10px;
  margin-bottom: 0 !important;
}
/*Start Scroll Bar Design*/
::-webkit-scrollbar {
  background-color: #ed9323;
  width: 8px;
}
::-webkit-scrollbar-thumb {
  background-color: #ed9323;
  border-radius: 7px
}

::-moz-scrollbar {
  background-color: #ed9323;
  width: 8px;
}
::-moz-scrollbar-thumb {
  background-color: #ed9323;
  border-radius: 7px
}

::-o-scrollbar {
  background-color: #ed9323;
  width: 8px;
}
::-o-scrollbar-thumb {
  background-color: #ed9323;
  border-radius: 7px;
}
::placeholder{
  font-size: 12px;
}
.label{
  text-align: start;
}
.dollar{
  text-align: start;
}