.navbar {
  z-index: 99999;
  background-color: #fff !important;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 16%), 0 1px 3px 0 rgb(0 0 0 / 12%);
  padding: 15px 0;
}
.navbar-brand img {
  width: 70%;
}
.navbar-expand-md .navbar-nav .nav-link {
  color: #0d025b;
  font-weight: 400;
  padding: 7px 0;
  font-size: .9rem;
  cursor: pointer;
  position: relative;
  background: transparent;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.navbar-expand-md .navbar-nav .nav-item {
  padding-left: 25px;
}
.navbar-expand-md .navbar-nav .nav-link:before {
  background-color: #ed9323;
  content: "";
  display: block;
  height: 2px;
  position: absolute;
  margin: 0 auto;
  width: 0;
  top: 30px;
  left: 0;
  right: 0;
  bottom: 0;
  transition: width 1s;
  -ms-transition: width 1s;
  -webkit-transition: width 1s;
}
.top-nav-collapse .navbar-nav li.active a.nav-link {
  color: #ed9323 !important;
  border-color: #ed9323;
}
.navbar-expand-md .navbar-nav .nav-link i {
  font-size: 14px;
  margin-left: 5px;
  vertical-align: middle;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.navbar-expand-md .navbar-nav li a:hover,
.navbar-expand-md .navbar-nav li .active > a,
.navbar-expand-md .navbar-nav li a:focus {
  color: #fff;
  outline: none;
}
.navbar-expand-md .navbar-nav .active > .nav-link,
.navbar-expand-md .navbar-nav .nav-link.active,
.navbar-expand-md .navbar-nav .nav-link.open,
.navbar-expand-md .navbar-nav .open > .nav-link {
  color: #ed9323 !important;
  width: 100%;
}
.navbar-expand-md .navbar-nav .nav-link:hover,
.navbar-expand-md .navbar-nav .nav-link:hover::before,
.navbar-expand-md .navbar-nav .nav-link:focus {
  color: #ed9323;
  width: 100%;
  transition: width 1s;
  -webkit-transition: width 1s;
  -ms-transition: width 1s;
}
.lang{
  text-align: end;
  display: inline-block;
  cursor: pointer;
  font-weight: 900;
  padding: .6rem 1.5rem;
  margin-left: auto;
  font-size: .9rem;
  background-color: #ed9323;
  border-radius: .5rem;
  color: #fff;
  width: fit-content;
  padding-bottom: .9rem;
  -webkit-border-radius: .5rem;
  -moz-border-radius: .5rem;
  -ms-border-radius: .5rem;
  -o-border-radius: .5rem;
}