#copyright .copyright-content {
  border-top: 1px solid #d5d4e9;
  padding: 15px 0;
  margin: 45px 0 0px;
}
#copyright p {
  line-height: 42px;
  color: #333;
  text-align: center;
  margin: 0;
}
#copyright p a {
  color: #ed9323;
}